import React from 'react'
import styled from 'styled-components'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import { Theme } from '@pga/pga-component-library'
import { parseFrontMatterFromQuery } from '../utils/index'
import VideoCard from '../components/VideoCard'

export const Container = styled.div.attrs({
  className: 'container'
})`
  padding-top: 2em;
  padding-bottom: 8em;
`

// Main Title text at the top of the page
export const PageTitle = styled.h1`
  font-size: 36px;
  text-align: ${props => props.center ? 'center' : 'left'};
  color: ${Theme.colors.primary};
  font-weight: 600;
  line-height: 1;

  small {
    font-size: 20px;
  }

  @media screen and (max-width: 576px) {
    font-size: 32px;
    small {
      font-size: 18px;
    }
  }
`

// Chunk of text at the top of the page, below the Title Text
export const PageSubTitle = styled.div`
  font-size: 16px;
  text-align: ${props => props.center ? 'center' : 'left'};
  padding: 15px 60px 15px;

  @media screen and (max-width: 576px) {
    padding: 20px 10px;
    font-size: 14px;
  }
`

// 50% (2 columns) initially, then stacked on mobile
export const VideoCardColumn = styled(VideoCard)`
  flex: 0 0 50%;
`

// Container for individual VideoCard components. Groups them
// in 2 equal columns, and then stacked on mobile.
export const VideoCards = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 40px;
  padding: 0 10px;

  @media screen and (max-width: 576px) {
    flex-direction: column;
    margin-top: 25px;

    ${VideoCardColumn} {
      flex: 1;
      &:not(:last-child) {
        margin-bottom: 1em;
      }
    }
  }
`

export default (props) => {
  const frontMatter = parseFrontMatterFromQuery(props)

  return (
    <Layout>
      <Container>
        <PageTitle center dangerouslySetInnerHTML={{ __html: frontMatter.title || '' }} />
        <PageSubTitle center dangerouslySetInnerHTML={{ __html: frontMatter.subtitle || '' }} />
        <VideoCards>
          <VideoCardColumn
            highlighted
            url={frontMatter.memberUrl}
            title={frontMatter.memberTitle}
            subTitle={frontMatter.memberSubtitle}
            image={frontMatter.memberImage}
            mobileImage={frontMatter.memberMobileImage}
          />
          <VideoCardColumn
            url={frontMatter.publicUrl}
            title={frontMatter.publicTitle}
            subTitle={frontMatter.publicSubtitle}
            image={frontMatter.publicImage}
            mobileImage={frontMatter.publicMobileImage}
          />
        </VideoCards>
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query {
    allMarkdownRemark(
      filter: {
        fields: {
          slug: {
            regex: "/^\/livestream$/"
          }
        }
      }
    ) {
      edges {
        node {
          fileAbsolutePath
          fields {
            slug
            category
            subCategory
            article
          }
          frontmatter {
            title: title_html
            subtitle: subtitle_html
            memberTitle: member_stream_title
            memberSubtitle: member_stream_subtitle_text
            memberImage: member_stream_image
            memberMobileImage: member_stream_mobile_image
            memberUrl: member_stream_url
            publicTitle: public_stream_title
            publicSubtitle: public_stream_subtitle_text
            publicImage: public_stream_image
            publicMobileImage: public_stream_mobile_image
            publicUrl: public_stream_url
            permalink
          }
        }
      }
    }
  }
`
