import React from 'react'
import styled from 'styled-components'
import { Theme } from '@pga/pga-component-library'

export const Title = styled.h3`
  font-size: 22px;
  color: ${Theme.colors.primary};
  font-weight: 600;
  font-family: Montserrat;
  margin: 0 0 5px;
`

export const Subtitle = styled.div`
  font-size: 16px;
  padding-bottom: 20px;
`

// Used to create rows and columns
export const Section = styled.div`
  flex: 1;
`

export const Wrapper = styled.div`
  border-radius: 9px;
  border: 1px solid ${props => props.highlighted ? Theme.colors.gold : 'white'};

  a {
    display: block;
  }

  @media screen and (max-width: 576px) {
    border-radius: 3px;
  }
`

export const Sections = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 25px;
  text-align: center;

  @media screen and (max-width: 576px) {
    flex-direction: row-reverse;
    align-items: center;
    padding: 10px;

    ${Section} {
      &:first-child {
        flex: 0 0 60%;
      }
      &:last-child {
        flex: 0 0 40%;
      }
    }

    ${Title} {
      font-size: 15px;
      padding: 0 0 0 15px;
      text-align: left;
    }

    ${Subtitle} {
      font-size: 12px;
      padding: 0 0 0 15px;
      text-align: left;
      font-weight: 500;
    }
  }
`

export default ({ title, subTitle, image, mobileImage, highlighted, url, className }) => (
  <Wrapper highlighted={highlighted} className={className}>
    <a href={url}>
      <Sections>
        <Section>
          <Title>{title}</Title>
          <Subtitle>{subTitle}</Subtitle>
        </Section>
        <Section>
          <img src={image} className='img-fluid w-100 d-none d-sm-block' alt='' />
          <img src={mobileImage} className='img-fluid w-100 d-block d-sm-none' alt='' />
        </Section>
      </Sections>
    </a>
  </Wrapper>
)
